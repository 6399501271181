import React, {useState, useEffect} from "react"
import {Container,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'
import SEO from "../../components/seo"

import Header from  "../../components/Common/Header"; 
import NavLink from  "../../components/NavLink/Custom_Presentation"; 
import {config} from '../../components/Common/constant';
import WorldClass_Photography from '../../components/modules/worldclass_photography';

import { GET_ARTICLE } from "../../queries/common_use_query";

const WorldClassPhotograph = () => {

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('world-class-photography');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

   return <>
   <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />
   <div className="pre-wrapper">

        <Header />

        <WorldClass_Photography  />
        
        <NavLink tab_name="Marketing" prevLink={config.custom_marketing_analytics}  nextLink={config.custom_digital_domination} />

    </div>
    </>
}

export default WorldClassPhotograph